import {
  Input,
  Modal,
  ModalBody,
  ModalDialog,
  ModalDisclosure,
  ModalDismiss,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  SecondaryButton,
  useSnackbar
} from '@woorcs/design-system'
import { Form, Formik, FormikHelpers } from 'formik'
import { useMutation } from 'urql'
import * as t from 'io-ts'
import { Email, getFormikValidator } from '@woorcs/utils/src/formik'
import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDialogContext } from '@ariakit/react'

import { FormField, SubmitButton } from '../Formik'

import { ShareSubmissionPdfMutationDocument } from './__generated__/ShareSubmissionPdfModal'

const shareSubmissionPdfFormCodec = t.type({
  email: Email
})

const initialValues = {
  email: '' as Email
}

type ShareSubmissionPdfFormValue = t.TypeOf<typeof shareSubmissionPdfFormCodec>

const validator = getFormikValidator(shareSubmissionPdfFormCodec)

interface ShareSubmissionPdfModalFormProps {
  submissionRevisionId: string
}

const ShareSubmissionPdfModalForm = ({
  submissionRevisionId
}: ShareSubmissionPdfModalFormProps) => {
  const dialog = useDialogContext()
  const { t } = useTranslation()
  const { showSnackbar } = useSnackbar()
  const [, shareSubmissionPdfMutation] = useMutation(
    ShareSubmissionPdfMutationDocument
  )

  const handleSubmit = useCallback(
    (
      value: ShareSubmissionPdfFormValue,
      helpers: FormikHelpers<ShareSubmissionPdfFormValue>
    ) => {
      shareSubmissionPdfMutation({
        input: {
          submissionRevisionId,
          email: value.email,
          language: null
        }
      })
        .then(() => {
          helpers.resetForm({})
          dialog?.hide()

          showSnackbar({
            title: 'Submission sent',
            variant: 'success'
          })
        })
        .catch(() => {
          showSnackbar({
            title: 'Failed to share submission',
            variant: 'danger'
          })
        })
        .finally(() => helpers.setSubmitting(false))
    },
    [dialog, shareSubmissionPdfMutation, showSnackbar, submissionRevisionId]
  )
  return (
    <Formik
      initialValues={initialValues}
      validate={validator}
      onSubmit={handleSubmit}
    >
      <Form>
        <ModalBody>
          <FormField
            name='email'
            infoMessage='A pdf version of the submission will be sent to the email address.'
            showMessages
          >
            <Input type='email' placeholder='Email' />
          </FormField>
        </ModalBody>

        <ModalFooter>
          <ModalDismiss render={<SecondaryButton mr={2} />}>
            {t('common:cancel')}
          </ModalDismiss>
          <SubmitButton label={t('common:send')} />
        </ModalFooter>
      </Form>
    </Formik>
  )
}

interface ShareSubmissionPdfModalProps {
  submissionRevisionId: string
  children: ReactElement
}

export const ShareSubmissionPdfModal = ({
  submissionRevisionId,
  children
}: ShareSubmissionPdfModalProps) => {
  return (
    <Modal>
      <ModalDisclosure>{children}</ModalDisclosure>

      <ModalDialog aria-label='Search'>
        <ModalHeader>
          <ModalTitle>Share Submission</ModalTitle>
        </ModalHeader>

        <ShareSubmissionPdfModalForm
          submissionRevisionId={submissionRevisionId}
        />
      </ModalDialog>
    </Modal>
  )
}
