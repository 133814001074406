import * as O from 'fp-ts/Option'
import { system, WithSystem } from '@woorcs/design-system'
import { pipe, constant } from 'fp-ts/function'
import { Locale } from '@woorcs/form'
import { findFlagUrlByIso2Code } from 'country-flags-svg-v2'

const getLanguageFlagUrl = (locale: Locale.Locale) =>
  pipe(
    Locale.getCountryCode(locale),
    O.fold(constant(''), (code) => findFlagUrlByIso2Code(code))
  )

type CountryFlagProps = WithSystem<{
  size?: number
  locale: Locale.Locale
}>

export const CountryFlag = ({
  locale,
  size = 24,
  ...other
}: CountryFlagProps) => {
  const url = getLanguageFlagUrl(locale)

  return (
    <system.img
      style={{
        width: size * 1.2,
        borderRadius: size * 0.15,
        height: size,
        objectFit: 'cover'
      }}
      src={url}
      {...other}
    />
  )
}
