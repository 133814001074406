import { AbsoluteFill, Box } from '@woorcs/design-system'
import { ReactNode } from 'react'
import { animated, useTransition } from 'react-spring'

import { OnboardingStep } from '@app/onboarding/model'

import { OnboardingWizardContextProvider } from './context'

type OnboardingWizardProps = {
  steps: OnboardingStep[]
  currentIndex: number
  onIndexChange(index: number): void
  renderStep(step: OnboardingStep): ReactNode
}

const wait = (time: number) =>
  new Promise((resolve) => window.setTimeout(resolve, time))

export const OnboardingWizard = ({
  currentIndex,
  steps,
  renderStep,
  onIndexChange
}: OnboardingWizardProps) => {
  const transitions = useTransition(currentIndex, {
    from: {
      opacity: 0,
      transform: 'translate3d(0, 40px, 0)',
      top: 0,
      left: 0
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    enter: () => async (next: any) => {
      await wait(500)

      await next({
        transform: 'translate3d(0, 0px, 0)',
        opacity: 1,
        top: 0,
        left: 0,
        delay: 0
      })

      await next({
        position: 'relative'
      })
    },
    leave: {
      opacity: 0,
      transform: 'translate3d(0, 0px, 0)',
      position: 'relative',
      top: 0,
      left: 0
    }
  })

  return (
    <OnboardingWizardContextProvider
      currentIndex={currentIndex}
      onIndexChange={onIndexChange}
    >
      <Box position='relative' width='100vw' height='100vh'>
        {transitions((style, index) => {
          return (
            <AbsoluteFill
              key={index}
              display='flex'
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
            >
              <animated.div style={style}>
                {renderStep(steps[index])}
              </animated.div>
            </AbsoluteFill>
          )
        })}
      </Box>
    </OnboardingWizardContextProvider>
  )
}
