import {
  Box,
  CheckIcon,
  Circle,
  Code,
  DangerIcon,
  Flex,
  Input,
  Popover,
  PopoverContent,
  PopoverDisclosure,
  Spinner,
  Switch,
  Text,
  useSnackbar
} from '@woorcs/design-system'
import { useMutation } from 'urql'
import * as t from 'io-ts'
import { getFormikValidator, Url } from '@woorcs/utils/formik'
import { useCallback, useEffect, useMemo } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik'

import { FormField } from '@app/components'

import {
  OrganizationPageOrganizationFragment,
  ToggleSubmissionPublishedWebhookEnabledMutationDocument,
  UpdateSubmissionPublishedWebhookUrlMutationDocument
} from './__generated__/OrganizationPage'

interface AutoSaveProps {
  debounceMs: number
}

const AutoSave = ({ debounceMs }: AutoSaveProps) => {
  const formik = useFormikContext()
  const debouncedSubmit = useDebouncedCallback(
    () => formik.submitForm(),
    debounceMs
  )

  useEffect(() => {
    if (!formik.dirty) {
      return
    }

    debouncedSubmit.callback()
  }, [debouncedSubmit, formik.dirty, formik.values])

  return null
}

const webhookUrlForm = t.type({
  url: Url
})

type WebhookUrlFormValue = t.TypeOf<typeof webhookUrlForm>

const validateUrlForm = getFormikValidator(webhookUrlForm)

interface UrlInputAddonProps {
  isSaving: boolean
  isError: boolean
}

const UrlInputAddon = ({ isSaving, isError }: UrlInputAddonProps) => {
  const formik = useFormikContext()

  if (isSaving) {
    return <Spinner size='small' />
  }

  if (isError) {
    return (
      <Circle
        radius={18}
        display='flex'
        bg='danger'
        justifyContent='center'
        alignItems='center'
      >
        <DangerIcon color='white' size='small' />
      </Circle>
    )
  }

  if (!formik.dirty && formik.isValid) {
    return (
      <Circle
        radius={18}
        display='flex'
        bg='success'
        justifyContent='center'
        alignItems='center'
      >
        <CheckIcon color='white' size='small' />
      </Circle>
    )
  }

  return null
}

interface SubmissionPublishedWebhookUrlFieldProps {
  organizationId: string
  initialUrl: string
}

const SubmissionPublishedWebhookUrlField = ({
  initialUrl,
  organizationId
}: SubmissionPublishedWebhookUrlFieldProps) => {
  const { showSnackbar } = useSnackbar()
  const [mutationState, updateSubmissionPublishedWebhookUrl] = useMutation(
    UpdateSubmissionPublishedWebhookUrlMutationDocument
  )
  const initialValues = useMemo((): WebhookUrlFormValue => {
    return {
      url: initialUrl as Url
    }
  }, [initialUrl])

  const handleSubmit = useCallback(
    (
      values: WebhookUrlFormValue,
      helpers: FormikHelpers<WebhookUrlFormValue>
    ) => {
      updateSubmissionPublishedWebhookUrl({
        input: {
          organizationId,
          url: values.url
        }
      })
        .then(() => {
          helpers.resetForm({ values })

          showSnackbar({
            variant: 'success',
            title: 'URL updated'
          })
        })
        .catch(() => {
          showSnackbar({
            title: 'Failed to update webhook URL',
            variant: 'danger'
          })
        })
        .finally(() => {
          helpers.setSubmitting(false)
        })
    },
    [organizationId, showSnackbar, updateSubmissionPublishedWebhookUrl]
  )

  return (
    <Formik
      initialValues={initialValues}
      validate={validateUrlForm}
      onSubmit={handleSubmit}
    >
      <Form>
        <AutoSave debounceMs={300} />
        <FormField name='url' label='Webhook URL' mb={2}>
          <Input
            rightAddon={
              <UrlInputAddon
                isSaving={mutationState.fetching}
                isError={Boolean(mutationState.error)}
              />
            }
          />
        </FormField>
      </Form>
    </Formik>
  )
}

interface SubmissionPublishedWebhookSettingsProps {
  organization: OrganizationPageOrganizationFragment
}

export const SubmissionPublishedWebhookSettings = ({
  organization
}: SubmissionPublishedWebhookSettingsProps) => {
  const { showSnackbar } = useSnackbar()
  const [, toggleSubmissionPublishedWebhookEnabled] = useMutation(
    ToggleSubmissionPublishedWebhookEnabledMutationDocument
  )

  const handleToggleSubmissionPublishedWebhookEnabled = () => {
    if (!organization) {
      return
    }

    const enabled = !organization.submissionPublishedWebhookEnabled

    toggleSubmissionPublishedWebhookEnabled({
      input: {
        organizationId: organization.id,
        enabled
      }
    })
      .then((result) => {
        if (result.error) {
          throw result.error
        }

        showSnackbar({
          variant: enabled ? 'success' : 'warning',
          title: enabled ? 'Webhook activated' : 'Webhook inactivated'
        })
      })
      .catch(() => {
        showSnackbar({
          variant: 'danger',
          title: enabled
            ? 'Failed to activate webhook'
            : 'Failed to deactivate webhook'
        })
      })
  }

  return (
    <Box px={4} py={6}>
      <Flex justifyContent='space-between'>
        <Box>
          <Text as='h4' fontWeight='semi-bold'>
            Submission published
          </Text>
          <Text fontSize='small'>Called when a submission is published.</Text>
          <Popover>
            <PopoverDisclosure>
              <Text
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}
                as='p'
                color='primary.500'
                fontSize='small'
              >
                See payload example
              </Text>
            </PopoverDisclosure>
            <PopoverContent>
              <Code language='json'>
                {`
{
"submission_id": string
"submission_revision_id": string
"updated_at": string
}
`}
              </Code>
            </PopoverContent>
          </Popover>
        </Box>

        <Switch
          checked={organization.submissionPublishedWebhookEnabled}
          onChange={handleToggleSubmissionPublishedWebhookEnabled}
        />
      </Flex>
      {organization.submissionPublishedWebhookEnabled && (
        <Box pt={4}>
          <SubmissionPublishedWebhookUrlField
            initialUrl={organization.submissionPublishedWebhookUrl ?? ''}
            organizationId={organization.id}
          />
        </Box>
      )}
    </Box>
  )
}
