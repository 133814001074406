import { ReactNode } from 'react'

import { FullscreenNavbar, FullscreenPage } from '@app/components'

type OnboardingLayoutChildren = {
  children: ReactNode
}

export const OnboardingLayout = ({ children }: OnboardingLayoutChildren) => {
  return (
    <FullscreenPage>
      <FullscreenNavbar />
      {children}
    </FullscreenPage>
  )
}
