import { Box, Button, Flex, Navbar, Text } from '@woorcs/design-system'
import { useAccount } from '@woorcs/api-client'
import { Trans, useTranslation } from 'react-i18next'

import { useAuth } from '@app/auth'

import { LanguageMenu } from '../LanguageMenu'

export const FullscreenNavbar = () => {
  const { t } = useTranslation('common')
  const [, { logout }] = useAuth()
  const account = useAccount()

  return (
    <Navbar bg='transparent' position='fixed' top={0} left={0} right={0}>
      <Box>
        <Text fontSize='small'>
          <Trans
            t={t}
            i18nKey='logged-in-as'
            values={{
              email: account.account.email
            }}
            // eslint-disable-next-line react/jsx-key
            components={[<Text fontWeight='bold' />]}
          />
        </Text>
      </Box>

      <Flex alignItems='center'>
        <LanguageMenu showLabel={false} />

        {account.account.superuser && (
          <Button as='a' variant='plain' {...({ href: '/admin' } as any)}>
            {t('go-to-admin')}
          </Button>
        )}

        <Button variant='plain' onClick={() => logout()}>
          {t('logout')}
        </Button>
      </Flex>
    </Navbar>
  )
}
