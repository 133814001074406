import { ReactNode } from 'react'

import { FullscreenNavbar, FullscreenPage } from '@app/components'

type LobbyLayoutProps = {
  children: ReactNode
}

export const LobbyLayout = ({ children }: LobbyLayoutProps) => (
  <FullscreenPage>
    <FullscreenNavbar />
    {children}
  </FullscreenPage>
)
