import { useCallback } from 'react'
import { Button, PlusIcon, useSnackbar } from '@woorcs/design-system'
import { FormikHelpers } from 'formik'
import * as Optional from 'monocle-ts/Optional'
import * as Option from 'fp-ts/Option'
import { pipe, constant } from 'fp-ts/function'
import { useMutation, useQuery, UseQueryState } from 'urql'

import {
  ListPage,
  ListPageContent,
  ListPageHeader,
  PageTitle
} from '@app/components'

import {
  SuperuserList,
  AddSuperuserModal,
  SuperUserFormUser
} from '../../components'

import {
  AddSuperuserDocument,
  RemoveSuperuserDocument,
  SuperUserListQuery,
  SuperUserListQueryDocument
} from './__generated__/SuperUsersPage'

const superUserListQueryOptional = pipe(
  Optional.id<SuperUserListQuery>(),
  Optional.prop('superusers')
)

const superUseListQueryResultOptional = pipe(
  Optional.id<UseQueryState<SuperUserListQuery>>(),
  Optional.prop('data'),
  Optional.fromNullable,
  Optional.compose(superUserListQueryOptional)
)

export const SuperUsersPage = () => {
  const { showSnackbar } = useSnackbar()
  const [query] = useQuery({
    query: SuperUserListQueryDocument
  })

  const superusers = pipe(
    query,
    superUseListQueryResultOptional.getOption,
    Option.getOrElseW(constant([]))
  )

  const [, addSuperuser] = useMutation(AddSuperuserDocument)
  // update(cache, { data }) {
  //   if (!data) {
  //     return
  //   }

  //   const addedUser = data.addSuperuser

  //   if (!addedUser) {
  //     return
  //   }

  //   const superusers = pipe(
  //     cache.readQuery({
  //       query: SuperUserListQueryDocument
  //     }) as SuperUserListQuery,
  //     superUserListQueryOptional.getOption,
  //     Option.getOrElseW(constant([]))
  //   )

  //   cache.writeQuery({
  //     query: SuperUserListQueryDocument,
  //     data: {
  //       superusers: [addedUser, ...superusers]
  //     }
  //   })
  // }
  const [, removeSuperuser] = useMutation(RemoveSuperuserDocument)

  const handleSubmit = useCallback(
    (user: SuperUserFormUser, actions: FormikHelpers<SuperUserFormUser>) => {
      addSuperuser({
        email: user.email
      })
        .then(() => {
          actions.resetForm({})
          showSnackbar({
            title: 'Superuser added',
            variant: 'success'
          })
        })
        .catch((e: Error) => {
          showSnackbar({
            title: e.message,
            variant: 'danger'
          })
        })
        .finally(() => {
          actions.setSubmitting(false)
        })
    },
    [showSnackbar, addSuperuser]
  )

  const handleRemoveSuperuser = useCallback(
    (accountId: string) => {
      removeSuperuser({
        input: {
          accountId
        }
      })
    },
    [removeSuperuser]
  )

  return (
    <ListPage>
      <ListPageHeader>
        <PageTitle>Superusers</PageTitle>
        <AddSuperuserModal title='Make superuser' onSubmit={handleSubmit}>
          <Button leftIcon={<PlusIcon />}>Add superuser</Button>
        </AddSuperuserModal>
      </ListPageHeader>
      <ListPageContent px={0} py={0}>
        <SuperuserList
          loading={query.fetching}
          superusers={superusers}
          onRemove={handleRemoveSuperuser}
        />
      </ListPageContent>
    </ListPage>
  )
}
