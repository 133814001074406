import { useMemo, ReactNode, Suspense } from 'react'
import {
  Box,
  Table,
  TableHead,
  TableHeadCell,
  TableBody,
  Pagination,
  InboxIcon,
  DateRangeTuple,
  Spinner,
  Flex,
  TableProps
} from '@woorcs/design-system'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import * as R from 'fp-ts/Record'
import { useTranslation } from 'react-i18next'
import { constant, pipe } from 'fp-ts/function'
import { space } from '@woorcs/utils'
import { useQuery } from 'urql'

import { ErrorView } from '../ErrorView'
import { useSubmissionListFiltersContext } from '../SubmissionListFilters/filters'
import { isEmptyFilters } from '../filters'
import { EmptyState } from '../EmptyState'

import { SubmissionListQueryDocument } from './__generated__/SubmissionList'
import { SubmissionListRow } from './Row'

const EmptyList = () => {
  const { t } = useTranslation('submissions')
  const { filters } = useSubmissionListFiltersContext()

  const filterDescriptionText = useMemo(() => {
    const activeFilters = pipe(
      R.toArray(filters),
      A.filterMap(([key, value]) => {
        if (O.isNone(value)) {
          return O.none
        }

        return O.some(key)
      })
    )

    if (activeFilters.length > 1) {
      return t('empty.description.no-results-for-filters')
    }

    switch (activeFilters[0]) {
      case 'formId': {
        return t('empty.description.no-results-for-form')
      }

      case 'creatorId': {
        return t('empty.description.no-results-for-user')
      }

      case 'published': {
        return t('empty.description.no-results-for-date-range')
      }

      case 'tags': {
        return t('empty.description.no-results-for-tags')
      }
    }
  }, [filters, t])

  if (isEmptyFilters(filters)) {
    return (
      <EmptyState
        Icon={InboxIcon}
        title={t('empty.title.no-filters')}
        description={t('empty.description.no-filters')}
      />
    )
  }

  return (
    <EmptyState
      Icon={InboxIcon}
      title={t('empty.title.no-results-for-filters')}
      description={filterDescriptionText}
    />
  )
}

const SubmissionListHeader = () => {
  const { t } = useTranslation('submissions')

  return (
    <TableHead>
      <TableHeadCell>{t('submissionList.columns.form.label')}</TableHeadCell>
      <TableHeadCell>
        {t('submissionList.columns.submittedBy.label')}
      </TableHeadCell>
      <TableHeadCell>{t('submissionList.columns.tags.label')}</TableHeadCell>
      <TableHeadCell>
        {t('submissionList.columns.language.label')}
      </TableHeadCell>
      <TableHeadCell>{t('submissionList.columns.updated.label')}</TableHeadCell>
      <TableHeadCell />
    </TableHead>
  )
}

interface SubmissionListTableProps {
  outerPadding: TableProps['outerPadding']
  children: ReactNode
}

const SubmissionListTable = ({
  outerPadding,
  children
}: SubmissionListTableProps) => {
  return (
    <Table outerPadding={outerPadding}>
      <SubmissionListHeader />
      {children}
    </Table>
  )
}

const SubmissionListErrorFallback = () => (
  <ErrorView body='Something went wrong while fetching the submissions' />
)

interface SubmissionListRowsProps {
  outerPadding?: TableProps['outerPadding']
}

const SubmissionListRows = ({ outerPadding }: SubmissionListRowsProps) => {
  const { filters, limit, offset, setOffset } =
    useSubmissionListFiltersContext()

  const variables = useMemo(
    () => ({
      from: pipe(
        filters.published,
        O.chain(DateRangeTuple.start),
        O.toUndefined
      ),
      to: pipe(filters.published, O.chain(DateRangeTuple.end), O.toUndefined),
      formId: O.toNullable(filters.formId),
      creatorId: O.toNullable(filters.creatorId),
      tags: pipe(filters.tags, O.getOrElseW(constant([]))),
      offset,
      limit
    }),
    [filters, limit, offset]
  )

  const [query] = useQuery({
    query: SubmissionListQueryDocument,
    variables,
    requestPolicy: 'network-only'
  })

  if (query.error) {
    return <SubmissionListErrorFallback />
  }

  const response = query.data?.submissions
  const data = response?.data

  if (!data || data.length === 0) {
    return <EmptyList />
  }

  const pageInfo = response.pageInfo
  const totalPages = Math.max(
    Math.ceil(pageInfo.totalCount / pageInfo.limit),
    1
  )

  const handlePageChange = (page: number) => {
    const offset = pageInfo.limit * (page - 1)

    setOffset(offset)
  }

  return (
    <Flex flexDirection='column' width='100%'>
      <SubmissionListTable outerPadding={outerPadding}>
        <TableBody>
          {data.map((submission) => (
            <SubmissionListRow key={submission.id} submission={submission} />
          ))}
        </TableBody>
      </SubmissionListTable>
      {totalPages > 1 && (
        <Box position='sticky' bottom={0} py={6} px={12} bg='background.base'>
          <Pagination
            currentPage={pageInfo.offset / pageInfo.limit + 1}
            totalPages={totalPages}
            onChange={handlePageChange}
          />
        </Box>
      )}
    </Flex>
  )
}

interface SubmissionListProps {
  outerPadding?: TableProps['outerPadding']
}

export const SubmissionList = ({
  outerPadding = space(12)
}: SubmissionListProps) => {
  return (
    <Suspense
      fallback={
        <Box
          width='100%'
          display='flex'
          py={10}
          justifyContent='center'
          alignItems='center'
        >
          <Spinner />
        </Box>
      }
    >
      <SubmissionListRows outerPadding={outerPadding} />
    </Suspense>
  )
}
