import { useParams } from 'react-router'
import { useQuery } from 'urql'
import { Suspense, useMemo } from 'react'
import { Box, Button, Flex, Subtitle, Text } from '@woorcs/design-system'
import { format } from 'date-fns'

import {
  PageContent,
  PageTitle,
  FullscreenLoader,
  PageHeader,
  Page
} from '@app/components'

import { OrganizationPageQueryDocument } from './__generated__/OrganizationPage'
import { SubmissionPublishedWebhookSettings } from './SubmissionPublishedWebhookSettings'

interface OrganizationPageContentProps {
  organizationId: string
}

const OrganizationPageContent = ({
  organizationId
}: OrganizationPageContentProps) => {
  const [query] = useQuery({
    query: OrganizationPageQueryDocument,
    variables: { id: organizationId },
    context: useMemo(
      () => ({
        suspense: true
      }),
      []
    )
  })

  const organization = query.data?.organization

  if (!organization) {
    return null
  }

  return (
    <Page>
      <PageHeader
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        borderBottom='base'
      >
        <Flex flexDirection='row' alignItems='center' gridColumnGap={4}>
          <Box>
            <PageTitle mb={2}>{organization?.name}</PageTitle>
            {organization.createdAt && (
              <Text>
                Created {format(new Date(organization.createdAt), 'yyyy-MM-dd')}
              </Text>
            )}
          </Box>
        </Flex>
        <Button
          as='a'
          {...({
            href: `/${organization.id}`
          } as any)}
        >
          Manage
        </Button>
      </PageHeader>
      <PageContent py={8}>
        <Box
          borderColor='grey.50'
          borderStyle='solid'
          borderWidth={1}
          borderRadius='large'
        >
          <Box>
            <Box
              p={4}
              borderBottomColor='grey.50'
              borderBottomStyle='solid'
              borderBottomWidth={1}
            >
              <Subtitle>Webhook settings</Subtitle>
            </Box>
            <SubmissionPublishedWebhookSettings organization={organization} />
          </Box>
        </Box>
      </PageContent>
    </Page>
  )
}

export const OrganizationPage = () => {
  const { organizationId } = useParams()

  return (
    <Suspense fallback={<FullscreenLoader />}>
      <OrganizationPageContent organizationId={organizationId as string} />
    </Suspense>
  )
}
