import React from 'react'
import {
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  Spinner,
  Box
} from '@woorcs/design-system'
import { space } from '@woorcs/utils'

import { OrganizationListOrganizationFragment } from './__generated__/OrganizationList'
import { OrganizationRow } from './OrganizationRow'

interface ListProps {
  organizations: OrganizationListOrganizationFragment[]
  onManageOrganization: (id: string) => void
  loading: boolean
}

export const OrganizationList = ({
  organizations,
  loading,
  onManageOrganization
}: ListProps) => {
  if (loading && !organizations) {
    return (
      <Box display='flex' py={10} justifyContent='center' alignItems='center'>
        <Spinner />
      </Box>
    )
  }

  return (
    <Table mb={5} outerPadding={space(14)}>
      <TableHead>
        <TableHeadCell>Name</TableHeadCell>
        <TableHeadCell />
      </TableHead>
      <TableBody>
        {organizations.map((organization, index) => (
          <OrganizationRow
            key={index}
            organization={organization}
            onManageClicked={onManageOrganization}
          />
        ))}
      </TableBody>
    </Table>
  )
}
