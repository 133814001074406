import { Box, Flex, Subtitle } from '@woorcs/design-system'
import { useViewer } from '@woorcs/graphql'
import { space } from '@woorcs/utils'
import { useTranslation } from 'react-i18next'

import {
  FiltersProvider,
  FullscreenNavbar,
  FullscreenPage,
  SubmissionList,
  SubmissionListFilters,
  useSubmissionFilters
} from '@app/components'

export const MySubmissionsPage = () => {
  const { t } = useTranslation('my-submissions')
  const viewer = useViewer()
  const filtersContext = useSubmissionFilters({
    creatorId: viewer.id
  })

  return (
    <FiltersProvider value={filtersContext}>
      <FullscreenPage
        justifyContent='flex-start'
        pt={[18, 18, 30]}
        pb={[4, 4, 18]}
        px={4}
      >
        <FullscreenNavbar />
        <Flex
          flexDirection='column'
          width='100%'
          height='100%'
          maxWidth={['100%', '100%', 960]}
          bg='white'
          borderRadius='large'
          boxShadow='large'
        >
          <Box
            borderBottomColor='grey.50'
            borderBottomStyle='solid'
            borderBottomWidth={1}
            p={6}
          >
            <Subtitle>{t('title')}</Subtitle>
          </Box>
          <SubmissionListFilters px={6} hiddenFilters={['creatorId']} />
          <Flex flex={1} height='100%' maxHeight='100%' overflowY='scroll'>
            <SubmissionList outerPadding={space(6)} />
          </Flex>
        </Flex>
      </FullscreenPage>
    </FiltersProvider>
  )
}
