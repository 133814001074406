import { useState, useCallback, useEffect } from 'react'
import copy from 'copy-to-clipboard'

export const useClipboard = (text: string, timeout = 1500) => {
  const [hasCopied, setHasCopied] = useState(false)

  const onCopy = useCallback(() => {
    const didCopy = copy(text)
    setHasCopied(didCopy)
  }, [text])

  useEffect(() => {
    if (hasCopied) {
      const id = window.setTimeout(() => {
        setHasCopied(false)
      }, timeout)

      return () => window.clearTimeout(id)
    }
  }, [timeout, hasCopied])

  return { value: text, onCopy, hasCopied }
}
