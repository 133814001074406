import { Flex, FlexProps } from '@woorcs/design-system'

type FullscreenPageProps = FlexProps

export const FullscreenPage = (props: FullscreenPageProps) => (
  <Flex
    flexDirection='column'
    alignItems='center'
    justifyContent='center'
    width='100%'
    height='100vh'
    overflow='scroll'
    pt={12}
    pb={12}
    bg='grey.50'
    {...props}
  />
)
