import { CombinedError } from 'urql'

import { EmptyState } from '@app/components'

import { GraphqlProvider } from './graphql'
import { AdminRouter } from './router'
import { ViewerProvider } from './graphql/ViewerProvider'

interface AdminAppProps {
  graphqlUrl: string
  basename: string
}

export const AdminApp = ({ graphqlUrl, basename }: AdminAppProps) => {
  const renderFailure = (error: CombinedError) => {
    if (error.networkError?.message === 'Forbidden') {
      return (
        <EmptyState
          status='danger'
          title="You don't have access to this page"
          description='If you think this is wrong contact an administrator.'
        />
      )
    }

    return (
      <EmptyState
        status='warning'
        title="We couldn't load this page"
        description='Please try again later.'
      />
    )
  }

  return (
    <GraphqlProvider url={graphqlUrl}>
      <ViewerProvider onFailure={renderFailure}>
        <AdminRouter basename={basename} />
      </ViewerProvider>
    </GraphqlProvider>
  )
}
