import { FilterList } from '../filters'
import { ListPageFilters, ListPageFiltersProps } from '../ListPage'
import { PaginiationLimitSelect } from '../PaginiationLimitSelect'

import { SubmissionFilter, useSubmissionListFiltersContext } from './filters'

interface SubmissionListFiltersProps extends ListPageFiltersProps {
  hiddenFilters?: SubmissionFilter[]
}

export const SubmissionListFilters = ({
  hiddenFilters,
  ...other
}: SubmissionListFiltersProps) => {
  const { limit, setLimit } = useSubmissionListFiltersContext()

  return (
    <ListPageFilters {...other}>
      <FilterList hiddenFilters={hiddenFilters} />

      <PaginiationLimitSelect value={limit} onChange={setLimit} />
    </ListPageFilters>
  )
}
