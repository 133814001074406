import { ReactNode } from 'react'
import {
  Box,
  Subtitle,
  MenuList,
  MenuListItem,
  MenuListItemProps,
  MenuListLink
} from '@woorcs/design-system'
import { useLinkClickHandler } from 'react-router-dom'
import { useHref, useLocation, useResolvedPath } from 'react-router'

const useLinkActive = (to: string, caseSensitive = false, end = false) => {
  const location = useLocation()
  const path = useResolvedPath(to)

  let locationPathname = location.pathname
  let toPathname = path.pathname
  if (!caseSensitive) {
    locationPathname = locationPathname.toLowerCase()
    toPathname = toPathname.toLowerCase()
  }

  return (
    locationPathname === toPathname ||
    (!end &&
      locationPathname.startsWith(toPathname) &&
      locationPathname.charAt(toPathname.length) === '/')
  )
}

interface MenuItemProps extends MenuListItemProps {
  to: string
  children: ReactNode
}

const MenuItem = ({ to, children, ...other }: MenuItemProps) => {
  const location = useLocation()
  const href = useHref(to)
  const handleClick = useLinkClickHandler(to, { state: location.state })
  const active = useLinkActive(to, false, true)

  return (
    <MenuListItem {...other}>
      <MenuListLink href={href} active={active} onClick={handleClick}>
        {children}
      </MenuListLink>
    </MenuListItem>
  )
}

export const SettingsMenu = () => {
  return (
    <Box>
      <Box mb={8}>
        <Subtitle mb={2}>Organization</Subtitle>
        <MenuList vertical>
          <MenuItem to='/settings/organization'>General</MenuItem>
          <MenuItem to='/settings/organization/tags'>Tags</MenuItem>
        </MenuList>
      </Box>
      <Box mb={8}>
        <Subtitle mb={2}>Account</Subtitle>
        <MenuList vertical>
          <MenuItem to='/settings/user/profile'>Profile</MenuItem>
          <MenuItem to='/settings/user/preferences'>Preferences</MenuItem>
        </MenuList>
      </Box>
    </Box>
  )
}
