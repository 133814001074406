import { ReactNode } from 'react'
import {
  Body,
  Box,
  Circle,
  Flex,
  FlexProps,
  FormIcon,
  H4,
  IconComponentType,
  matchStatus,
  Status
} from '@woorcs/design-system'
import { pipe } from 'fp-ts/function'

type StatusIconProps = {
  Icon: IconComponentType
  status: Status
}

const StatusIcon = ({ Icon, status }: StatusIconProps) => {
  const colors = pipe(
    status,
    matchStatus({
      success: () => ({
        bg: 'secondary.50',
        fg: 'secondary.500'
      }),
      danger: () => ({
        bg: 'red.50',
        fg: 'red.500'
      }),
      warning: () => ({
        bg: 'orange.50',
        fg: 'orange.500'
      }),
      info: () => ({
        bg: 'primary.50',
        fg: 'primary.500'
      })
    })
  )

  return (
    <Circle
      display='inline-flex'
      justifyContent='center'
      alignItems='center'
      radius={64}
      bg={colors.bg}
    >
      <Icon size='medium' color={colors.fg} />
    </Circle>
  )
}

interface EmptyStateProps extends FlexProps {
  title: string
  description: string
  Icon?: IconComponentType
  status?: Status
  children?: ReactNode
}

export const EmptyState = ({
  title,
  description,
  Icon = FormIcon,
  status = 'warning',
  children,
  ...other
}: EmptyStateProps) => {
  return (
    <Flex
      flexGrow={1}
      height='100%'
      width='100%'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      {...other}
    >
      <Box textAlign='center' maxWidth={640}>
        <Box mb={8}>
          <Box mb={6}>
            <StatusIcon status={status} Icon={Icon} />
          </Box>

          <H4 mb={2}>{title}</H4>
          <Body>{description}</Body>
        </Box>
        <Box mb={12}>{children}</Box>
      </Box>
    </Flex>
  )
}
