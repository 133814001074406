import React, { ReactElement, useCallback } from 'react'
import {
  Box,
  Text,
  NAV_SIDEBAR_WIDTH,
  Menu,
  MenuButton,
  MenuContent,
  MenuItem,
  MenuSeparator,
  ChevronRightIcon,
  Avatar
} from '@woorcs/design-system'
import * as A from 'fp-ts/Array'
import { pipe } from 'fp-ts/function'
import * as Optional from 'monocle-ts/Optional'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import history from 'history/browser'
import { queryToDatumEither, useOrganization } from '@woorcs/graphql'
import { useQuery } from 'urql'
import * as DE from '@nll/datum/DatumEither'
import * as O from 'fp-ts/Option'
import { constNull } from 'fp-ts/es6/function'

import { navigateToOrganization } from '@app/utils'

import {
  OrganizationMenuOrganizationFragment,
  OrganizationMenuQuery,
  OrganizationMenuQueryDocument
} from './__generated__/OrganizationMenu'

type SelectOrganizationMenuProps = {
  currentOrganization: OrganizationMenuOrganizationFragment
  organizations: OrganizationMenuOrganizationFragment[]
  children: ReactElement
}

const SelectOrganizationMenu = ({
  currentOrganization,
  organizations,
  children
}: SelectOrganizationMenuProps) => {
  const handleSelectOrganization = useCallback((id: string) => {
    navigateToOrganization(id)
  }, [])
  const organizationsWithoutCurrent = organizations.filter(
    (organization) => organization.id !== currentOrganization.id
  )

  if (organizationsWithoutCurrent.length === 0) {
    return null
  }

  return (
    <Menu placement='right-end'>
      <MenuButton>{children}</MenuButton>

      <MenuContent aria-label='Select organization' modal>
        <Box width={NAV_SIDEBAR_WIDTH - 32} maxHeight={240} overflow='scroll'>
          {organizationsWithoutCurrent.map((organization) => (
            <MenuItem
              key={organization.id}
              onClick={() => handleSelectOrganization(organization.id)}
            >
              <Avatar
                size='mini'
                name={organization.name}
                bg='secondary.300'
                color='green.700'
                mr={2}
                circle={false}
              />
              {organization.name}
            </MenuItem>
          ))}
        </Box>
      </MenuContent>
    </Menu>
  )
}

const usersQueryResponse = pipe(
  Optional.id<OrganizationMenuQuery>(),
  Optional.prop('viewer'),
  Optional.prop('account'),
  Optional.prop('users')
)

export const SidebarOrganizationMenu = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const query = useQuery({
    query: OrganizationMenuQueryDocument
  })
  const currentOrganization = useOrganization()
  const handleSettingsClick = useCallback(() => {
    navigate('/settings/organization', {
      state: {
        backgroundLocation: location
      }
    })
  }, [location, navigate])

  const handleCreateOrganizationClick = useCallback(() => {
    history.push('/create-organization')
  }, [])

  return pipe(
    query,
    queryToDatumEither(usersQueryResponse),
    DE.squash(constNull, constNull, (users) =>
      pipe(
        users,
        A.map((user) => user.organization),
        (organizations) => (
          <Menu placement='top'>
            <MenuButton>
              <Box
                display='flex'
                alignItems='center'
                __css={{
                  cursor: 'pointer',
                  ':focus': {
                    outline: 'none'
                  },
                  ':hover': {
                    backgroundColor: 'grey.50'
                  }
                }}
              >
                <Avatar
                  size='large'
                  src={O.fromNullable(currentOrganization.logo)}
                  name={currentOrganization.name}
                  bg='secondary.300'
                  color='green.700'
                  circle={false}
                />
              </Box>
            </MenuButton>

            <MenuContent
              aria-label='Organization menu'
              width={NAV_SIDEBAR_WIDTH - 28}
            >
              <MenuItem onClick={handleSettingsClick}>
                {t('common:settings')}
              </MenuItem>

              <MenuSeparator />

              <SelectOrganizationMenu
                currentOrganization={currentOrganization}
                organizations={organizations}
              >
                <MenuItem justifyContent='space-between'>
                  <Text>
                    {t(
                      'app:sidebar.currentOrganization.menu.changeOrganization.label'
                    )}
                  </Text>
                  <ChevronRightIcon size='small' />
                </MenuItem>
              </SelectOrganizationMenu>

              <MenuItem onClick={handleCreateOrganizationClick}>
                Create organization
              </MenuItem>
            </MenuContent>
          </Menu>
        )
      )
    )
  )
}
