import { NavLink } from 'react-router-dom'
import { Text } from '@woorcs/design-system'

import {
  FullscreenNavbar,
  FullscreenPage,
  ResourceNotFound
} from '@app/components'

export const NotFoundPage = () => {
  return (
    <FullscreenPage bg='white' justifyContent='center'>
      <FullscreenNavbar />
      <ResourceNotFound
        title='Not found'
        description={`We cant find the page that you're looking for`}
      >
        <NavLink to='/'>
          <Text color='primary.500' textDecoration='underline'>
            Go back home
          </Text>
        </NavLink>
      </ResourceNotFound>
    </FullscreenPage>
  )
}
