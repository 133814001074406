import {
  OptimisticMutationResolver,
  UpdateResolver
} from '@urql/exchange-graphcache'
import { pipe, constNull } from 'fp-ts/function'
import { Endomorphism } from 'fp-ts/Endomorphism'
import * as O from 'fp-ts/Option'
import * as Optional from 'monocle-ts/Optional'
import * as Lens from 'monocle-ts/Lens'

import { SuperuserListUserFragment } from '../components/SuperuserList/__generated__/SuperuserList'
import {
  RemoveSuperuserMutation,
  RemoveSuperuserMutationVariables,
  SuperUserListQuery,
  SuperUserListQueryDocument
} from '../pages/superusers/__generated__/SuperUsersPage'
import {
  ToggleSubmissionPublishedWebhookEnabledMutationVariables,
  UpdateSubmissionPublishedWebhookUrlMutationVariables
} from '../pages/organization/__generated__/OrganizationPage'

const superuserListQueryResponseLens = pipe(
  Lens.id<SuperUserListQuery>(),
  Lens.prop('superusers')
)

const superuserListQueryResponseOptional = Lens.asOptional(
  superuserListQueryResponseLens
)

const updateSuperuserListResponseData = (
  update: Endomorphism<SuperuserListUserFragment[]>
) => pipe(superuserListQueryResponseOptional, Optional.modify(update))

const removeUser = (accountId: string) =>
  pipe(
    updateSuperuserListResponseData((data) => {
      return data.filter((user) => accountId !== user.account.id)
    })
  )

const removeSuperuser: UpdateResolver<
  RemoveSuperuserMutation,
  RemoveSuperuserMutationVariables
> = (_, { input: { accountId } }, cache) => {
  cache.updateQuery({ query: SuperUserListQueryDocument }, (superuserList) =>
    pipe(
      O.fromNullable(superuserList),
      O.map(removeUser(accountId)),
      O.getOrElseW(constNull)
    )
  )
}

const toggleSubmissionPublishedWebhookEnabled: OptimisticMutationResolver<
  ToggleSubmissionPublishedWebhookEnabledMutationVariables
> = ({ input: { organizationId, enabled } }) => {
  return {
    __typename: 'ToggleSubmissionPublishedWebhookEnabledSuccess',
    organization: {
      __typename: 'Organization',
      id: organizationId,
      submissionPublishedWebhookEnabled: enabled
    }
  }
}

const updateSubmissionPublishedWebhookUrl: OptimisticMutationResolver<
  UpdateSubmissionPublishedWebhookUrlMutationVariables
> = ({ input: { organizationId, url } }) => {
  return {
    __typename: 'UpdateSubmissionPublishedWebhookUrlSuccess',
    organization: {
      __typename: 'Organization',
      id: organizationId,
      submissionPublishedWebhookUrl: url
    }
  }
}

export const updates = {
  Mutation: {
    removeSuperuser
  },
  Subscription: {}
}

export const optimistic = {
  toggleSubmissionPublishedWebhookEnabled,
  updateSubmissionPublishedWebhookUrl
}
