import { useCallback, forwardRef } from 'react'
import {
  Menu,
  MenuButton,
  MenuContent,
  MenuOption,
  MenuOptionGroup,
  Badge,
  Text,
  MenuItemButton,
  MenuButtonProps
} from '@woorcs/design-system'
import { useTranslation } from 'react-i18next'
import { CountryFlag } from '@woorcs/inspection-form-editor'

type LanguageMenuProps = Omit<MenuButtonProps, 'children'> & {
  showLabel?: boolean
}

export const LanguageMenu = forwardRef<HTMLButtonElement, LanguageMenuProps>(
  ({ showLabel = true }, ref) => {
    const { t, i18n } = useTranslation(['common'])
    const currentLanguage = i18n.languages[0]
    const handleChange = useCallback(
      (value: string) => {
        i18n.changeLanguage(value)
      },
      [i18n]
    )

    return (
      <Menu placement='bottom-end'>
        <MenuButton ref={ref}>
          <MenuItemButton
            css={{
              display: 'flex'
            }}
            flexDirection='row'
            width='100%'
            justifyContent='space-between'
            alignItems='center'
          >
            {showLabel && <Text mr={4}>{t('common:language')}</Text>}

            <Badge>
              <CountryFlag size={12} locale={currentLanguage} mr={1} />
              {t(`languages.${currentLanguage}` as any)}
            </Badge>
          </MenuItemButton>
        </MenuButton>
        <MenuContent aria-label='Language select'>
          <MenuOptionGroup
            name='range'
            type='radio'
            value={currentLanguage}
            onChange={handleChange}
          >
            <MenuOption value='sv'>{t(`common:languages.sv`)}</MenuOption>
            <MenuOption value='en'>{t(`common:languages.en`)}</MenuOption>
          </MenuOptionGroup>
        </MenuContent>
      </Menu>
    )
  }
)
