import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { AdminLayout } from './components'
import { SuperUsersPage, OrganizationsPage, OrganizationPage } from './pages'

interface AdminRouterProps {
  basename: string
}

export const AdminRouter = ({ basename }: AdminRouterProps) => {
  return (
    <BrowserRouter basename={basename}>
      <Routes>
        <Route path='*' element={<AdminLayout />}>
          <Route element={<OrganizationsPage />} index />
          <Route
            path='organization/:organizationId'
            element={<OrganizationPage />}
          />
          <Route path='superusers' element={<SuperUsersPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
