import React, { ReactNode } from 'react'
import { rem } from '@woorcs/utils'

import { Text, TextProps } from '../../Text'

interface Props extends TextProps {
  children: ReactNode
}

export const InlineCode = ({ children, ...other }: Props) => (
  <Text
    __css={{
      px: rem(6),
      py: rem(4),
      borderRadius: 'small',
      fontSize: '1em',
      fontFamily: 'monospace',
      color: 'accent.600',
      backgroundColor: 'accent.100'
    }}
    {...other}
  >
    {children}
  </Text>
)
