import { ReactElement, useCallback } from 'react'
import { Flex } from '@woorcs/design-system'
import * as R from 'fp-ts/Record'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import * as string from 'fp-ts/string'
import { constant, pipe } from 'fp-ts/function'

import { useFiltersContext } from '../../context'
import { FilterButton } from '../FilterButton'
import { FilterMenu } from '../FilterMenu'

interface FilterListProps {
  hiddenFilters?: string[]
}

export const FilterList = ({ hiddenFilters = [] }: FilterListProps) => {
  const { filters, setFilter, availableFilters } = useFiltersContext()

  const handleRemoveFilter = useCallback(
    (key: string) => {
      setFilter(key, O.none)
    },
    [setFilter]
  )

  return (
    <Flex>
      <FilterMenu />

      {pipe(
        filters,
        R.filter(O.isSome),
        R.filterWithIndex((key) => !hiddenFilters.includes(key)),
        R.reduceWithIndex(string.Ord)(
          [] as ReactElement[],
          (key, items, value) =>
            pipe(
              value,
              O.fold(constant(items), (value) =>
                pipe(
                  items,
                  A.append(
                    <FilterButton
                      key={key}
                      filterKey={key}
                      filter={availableFilters[key]}
                      value={value}
                      onRemove={handleRemoveFilter}
                      onChange={setFilter}
                    />
                  )
                )
              )
            )
        )
      )}
    </Flex>
  )
}
