import * as SchemaTypes from '@app/admin/schema/schema.generated';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/* eslint-disable max-lines, no-use-before-define, camelcase, no-unused-vars */
// ⚠️ DO NOT EDIT ⚠️
// This file is automatically generated, run yarn run graphql:codegen to update
  
export type OrganizationPageOrganizationFragment = { __typename: 'Organization', id: string, name: string, logo: string | null, submissionPublishedWebhookEnabled: boolean, submissionPublishedWebhookUrl: string | null, createdAt: any };

export type OrganizationPageQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID'];
}>;


export type OrganizationPageQuery = { __typename: 'Query', organization: { __typename: 'Organization', id: string, name: string, logo: string | null, submissionPublishedWebhookEnabled: boolean, submissionPublishedWebhookUrl: string | null, createdAt: any } | null };

export type ToggleSubmissionPublishedWebhookEnabledMutationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.ToggleSubmissionPublishedWebhookEnabledInput;
}>;


export type ToggleSubmissionPublishedWebhookEnabledMutation = { __typename: 'Mutation', toggleSubmissionPublishedWebhookEnabled: { __typename: 'ToggleSubmissionPublishedWebhookEnabledSuccess', organization: { __typename: 'Organization', id: string, submissionPublishedWebhookEnabled: boolean } } };

export type UpdateSubmissionPublishedWebhookUrlMutationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UpdateSubmissionPublishedWebhookUrlInput;
}>;


export type UpdateSubmissionPublishedWebhookUrlMutation = { __typename: 'Mutation', updateSubmissionPublishedWebhookUrl: { __typename: 'InvalidWebhookUrlError' } | { __typename: 'UpdateSubmissionPublishedWebhookUrlSuccess', organization: { __typename: 'Organization', id: string, submissionPublishedWebhookUrl: string | null } } };

export const OrganizationPageOrganizationFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"OrganizationPageOrganization"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Organization"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"logo"}},{"kind":"Field","name":{"kind":"Name","value":"submissionPublishedWebhookEnabled"}},{"kind":"Field","name":{"kind":"Name","value":"submissionPublishedWebhookUrl"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}}]} as unknown as DocumentNode<OrganizationPageOrganizationFragment, unknown>;
export const OrganizationPageQueryDocument = {"kind":"Document", "definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"OrganizationPageQuery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organization"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OrganizationPageOrganization"}}]}}]}},...OrganizationPageOrganizationFragmentDoc.definitions]} as unknown as DocumentNode<OrganizationPageQuery, OrganizationPageQueryVariables>;
export const ToggleSubmissionPublishedWebhookEnabledMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ToggleSubmissionPublishedWebhookEnabledMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ToggleSubmissionPublishedWebhookEnabledInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"toggleSubmissionPublishedWebhookEnabled"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ToggleSubmissionPublishedWebhookEnabledSuccess"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organization"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"submissionPublishedWebhookEnabled"}}]}}]}}]}}]}}]} as unknown as DocumentNode<ToggleSubmissionPublishedWebhookEnabledMutation, ToggleSubmissionPublishedWebhookEnabledMutationVariables>;
export const UpdateSubmissionPublishedWebhookUrlMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateSubmissionPublishedWebhookUrlMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateSubmissionPublishedWebhookUrlInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateSubmissionPublishedWebhookUrl"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateSubmissionPublishedWebhookUrlSuccess"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organization"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"submissionPublishedWebhookUrl"}}]}}]}}]}}]}}]} as unknown as DocumentNode<UpdateSubmissionPublishedWebhookUrlMutation, UpdateSubmissionPublishedWebhookUrlMutationVariables>;