import { ReactNode, ReactElement } from 'react'
import {
  NavSidebar,
  NavSidebarMenuItem,
  NavSidebarMenuItemProps,
  DocumentIcon,
  NavSidebarMenu,
  UserIcon,
  Box,
  Flex,
  Avatar,
  Text
} from '@woorcs/design-system'
import { NavLink } from 'react-router-dom'

import { UserMenu } from '@app/components/UserMenu'
import { useViewer } from '@app/admin/graphql'

interface MenuItemProps extends NavSidebarMenuItemProps {
  to: string
  icon: ReactElement
  children: ReactNode
}

const MenuItem = ({ to, icon, children, ...other }: MenuItemProps) => (
  <NavSidebarMenuItem as={NavLink} icon={icon} {...({ to } as any)} {...other}>
    {children}
  </NavSidebarMenuItem>
)

export const AdminSidebar = () => {
  const viewer = useViewer()

  return (
    <NavSidebar>
      <Box>
        <Flex mb={12} px={6}>
          <Text
            style={{ cursor: 'default', userSelect: 'none' }}
            fontWeight='bold'
            color='primary.500'
          >
            Woorcs
          </Text>
        </Flex>

        <NavSidebarMenu>
          <MenuItem to='/' icon={<DocumentIcon />} aria-label='organizations'>
            Organizations
          </MenuItem>
          <MenuItem to='superusers' icon={<UserIcon />} aria-label='superusers'>
            Superusers
          </MenuItem>
        </NavSidebarMenu>
      </Box>

      <Box px={2}>
        <UserMenu showSettings={false}>
          <Box
            __css={{
              cursor: 'pointer',
              ':hover': {
                bg: 'primary.50'
              }
            }}
            borderRadius='large'
            display='flex'
            alignItems='center'
            p={2}
          >
            <Flex alignItems='center'>
              <Avatar
                name={viewer.account.email}
                bg='accent.100'
                color='accent.500'
                size='small'
                mr={2}
              />

              <Text display='block' fontSize='mini' fontWeight='bold'>
                {viewer.account.email}
              </Text>
            </Flex>
          </Box>
        </UserMenu>
      </Box>
    </NavSidebar>
  )
}
