/* eslint-disable react/jsx-key */
/* eslint-disable max-statements */
/* eslint-disable complexity */
import { Box, Flex, SmallBody, Text } from '@woorcs/design-system'
import { useQuery } from 'urql'
import { useMemo } from 'react'
import { formatDistance } from 'date-fns'
import { NavLink } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

import {
  ActivityLogListEntryFragment,
  ActivityLogQueryDocument
} from './__generated__/ActivityLogList'

const useActivityDescription = (activity: ActivityLogListEntryFragment) => {
  const { t } = useTranslation('activity-log-list')

  switch (activity.__typename) {
    case 'FormCreatedActivity': {
      return (
        <Text>
          <Trans
            i18nKey='description.form-created'
            t={t}
            values={{
              title: activity.title
            }}
            components={[
              <NavLink to={`/forms/${activity.affectedEntityId}`} />
            ]}
          />
        </Text>
      )
    }

    case 'FormUpdatedActivity': {
      return (
        <Text>
          <Trans
            i18nKey='description.form-updated'
            t={t}
            values={{
              title: activity.title
            }}
            components={[
              <NavLink to={`/forms/${activity.affectedEntityId}`} />
            ]}
          />
        </Text>
      )
    }

    case 'FormPublishedActivity': {
      return (
        <Text>
          <Trans
            i18nKey='description.form-published'
            t={t}
            values={{
              title: activity.title
            }}
            components={[
              <NavLink to={`/forms/${activity.affectedEntityId}`} />
            ]}
          />
        </Text>
      )
    }

    case 'FormDeletedActivity': {
      return (
        <Text>
          <Trans
            i18nKey='description.form-deleted'
            t={t}
            values={{
              title: activity.title
            }}
            components={[
              <NavLink to={`/forms/${activity.affectedEntityId}`} />
            ]}
          />
        </Text>
      )
    }

    case 'FormRevisionDeletedActivity': {
      return <Text>{t('description.form-revision-deleted')}</Text>
    }

    case 'SubmissionCreatedActivity': {
      return (
        <Text>
          <Trans
            i18nKey='description.submission-created'
            t={t}
            values={{
              formTitle: activity.form
            }}
            components={[
              <NavLink to={`/submissions/${activity.affectedEntityId}`} />
            ]}
          />
        </Text>
      )
    }

    case 'SubmissionUpdatedActivity': {
      return (
        <Text>
          <Trans
            i18nKey='description.submission-updated'
            t={t}
            values={{
              formTitle: activity.form,
              revisionId: activity.revisionId
            }}
            components={[
              <NavLink to={`/submissions/${activity.affectedEntityId}`} />,
              <Text fontWeight='semi-bold' />
            ]}
          />
        </Text>
      )
    }

    case 'SubmissionLocationUpdatedActivity': {
      return (
        <Text>
          <Trans
            i18nKey='description.submission-location-updated'
            t={t}
            values={{
              formTitle: activity.form,
              submissionId: activity.affectedEntityId
            }}
            components={[
              <NavLink to={`/submissions/${activity.affectedEntityId}`} />,
              <Text fontWeight='semi-bold' />
            ]}
          />
        </Text>
      )
    }

    case 'SubmissionPublishedActivity': {
      return (
        <Trans
          i18nKey='description.submission-published'
          t={t}
          values={{
            formTitle: activity.form,
            submissionId: activity.affectedEntityId
          }}
          components={[
            <NavLink to={`/submissions/${activity.affectedEntityId}`} />,
            <Text fontWeight='semi-bold' />
          ]}
        />
      )
    }

    case 'SubmissionDeletedActivity': {
      return (
        <Trans
          i18nKey='description.submission-deleted'
          t={t}
          values={{
            submissionId: activity.affectedEntityId
          }}
        />
      )
    }

    case 'SubmissionRevisionCreatedActivity': {
      return (
        <Trans
          i18nKey='description.submission-revision-created'
          t={t}
          values={{
            version: activity.version,
            submissionId: activity.affectedEntityId
          }}
          components={[
            <NavLink to={`/submissions/${activity.affectedEntityId}`} />,
            <Text fontWeight='semi-bold' />
          ]}
        />
      )
    }

    case 'SubmissionRevisionDeletedActivity': {
      return (
        <Trans
          i18nKey='description.submission-revision-deleted'
          t={t}
          values={{
            version: activity.version,
            submissionId: activity.affectedEntityId
          }}
          components={[
            <NavLink to={`/submissions/${activity.affectedEntityId}`} />,
            <Text fontWeight='semi-bold' />
          ]}
        />
      )
    }

    case 'SubmissionUnpublishedActivity': {
      return <Text>{t('description.submission-unpublished')}</Text>
    }

    case 'ProfileUpdatedActivity': {
      return <Text>{t('description.profile-updated')}</Text>
    }

    case 'ProfileCreatedActivity': {
      return <Text>{t('description.profile-created')}</Text>
    }

    case 'ProfilesDeletedActivity': {
      return <Text>{t('description.profiles-deleted')}</Text>
    }

    case 'ProfileDisabledActivity': {
      return <Text>{t('description.profile-disabled')}</Text>
    }

    case 'ProfileEnabledActivity': {
      return <Text>{t('description.profile-enabled')}</Text>
    }

    case 'TagCreatedActivity': {
      return <Text>{t('description.tag-created')}</Text>
    }

    case 'TagUpdatedActivity': {
      return <Text>{t('description.tag-updated')}</Text>
    }

    case 'TagDeletedActivity': {
      return <Text>{t('description.tag-deleted')}</Text>
    }

    // superadmin activities

    case 'SuperuserAddedActivity': {
      return <Text>{t('description.superuser-added')}</Text>
    }

    case 'SuperuserRemovedActivity': {
      return <Text>{t('description.superuser-removed')}</Text>
    }

    case 'OrganizationCreatedActivity': {
      return <Text>{t('description.organization-created')}</Text>
    }

    default: {
      return `Unknown activity: ${activity.action}`
    }
  }
}

interface ActivityLogListItemProps {
  activity: ActivityLogListEntryFragment
  separatorColor?: string
  isLast: boolean
}

const ActivityLogListItem = ({
  activity,
  separatorColor = 'grey.100',
  isLast
}: ActivityLogListItemProps) => {
  const description = useActivityDescription(activity)

  return (
    <Flex
      width='100%'
      justifyContent='space-between'
      borderBottom={isLast ? 0 : 'thin'}
      borderBottomColor={separatorColor}
      py={3}
      gap={8}
    >
      <SmallBody flex={1}>
        <NavLink to={`/users/${activity.causedBy?.id}`}>
          <Text fontWeight='semi-bold' color='primary.500'>
            {`${activity.causedBy?.name ?? 'Unknown'} `}
          </Text>
        </NavLink>
        <Text>{description}</Text>
      </SmallBody>
      <SmallBody textAlign='right'>{`${formatDistance(
        new Date(activity.createdAt),
        new Date(),
        {
          addSuffix: true
        }
      )} `}</SmallBody>
    </Flex>
  )
}

interface ActitivityLogListProps {
  limit?: number
  offset?: number
  userId?: string | null
  separatorColor?: string
}

export const ActitivityLogList = ({
  limit = 20,
  offset = 0,
  userId = null,
  separatorColor
}: ActitivityLogListProps) => {
  const { t } = useTranslation('activity-log-list')
  const [query] = useQuery({
    query: ActivityLogQueryDocument,
    variables: useMemo(
      () => ({
        input: {
          limit,
          offset,
          userId
        }
      }),
      [limit, offset, userId]
    ),
    context: useMemo(
      () => ({
        suspense: true
      }),
      []
    )
  })

  const data = query.data?.activityLog.data ?? []

  if (!data.length) {
    return (
      <Box>
        <Text>{t('empty-state')}</Text>
      </Box>
    )
  }

  return (
    <Box>
      {data.map((activity, index) => (
        <ActivityLogListItem
          key={activity.id}
          activity={activity}
          separatorColor={separatorColor}
          isLast={index === data.length - 1}
        />
      ))}
    </Box>
  )
}
