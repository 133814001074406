import * as SchemaTypes from '@app/admin/schema/schema.generated';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/* eslint-disable max-lines, no-use-before-define, camelcase, no-unused-vars */
// ⚠️ DO NOT EDIT ⚠️
// This file is automatically generated, run yarn run graphql:codegen to update
  
export type ViewerFragment = { __typename: 'User', id: string, name: string, firstName: string | null, lastName: string | null, account: { __typename: 'Account', id: string, email: string, superuser: boolean } };

export type ViewerQueryVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


export type ViewerQuery = { __typename: 'Query', viewer: { __typename: 'User', id: string, name: string, firstName: string | null, lastName: string | null, account: { __typename: 'Account', id: string, email: string, superuser: boolean } } };

export const ViewerFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ViewerFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"account"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"superuser"}}]}}]}}]} as unknown as DocumentNode<ViewerFragment, unknown>;
export const ViewerQueryDocument = {"kind":"Document", "definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ViewerQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ViewerFragment"}}]}}]}},...ViewerFragmentDoc.definitions]} as unknown as DocumentNode<ViewerQuery, ViewerQueryVariables>;