import { Box, Flex, FlexProps } from '@woorcs/design-system'
import React, { ReactNode } from 'react'

type ListPageHeaderGroupProps = FlexProps

export const ListPageHeaderGroup = (props: ListPageHeaderGroupProps) => (
  // TODO: remove z-index, atm it's need as some items in our lists (such as checkboxes)
  //       are displayed on top of the header.
  <Box position='sticky' bg='white' top={0} zIndex={10} {...props} />
)

type ListPageHeaderProps = FlexProps

export const ListPageHeader = (props: ListPageHeaderProps) => (
  <Flex
    pt={12}
    pb={8}
    px={12}
    justifyContent='space-between'
    alignItems='flex-end'
    borderBottom='base'
    {...props}
  />
)

export type ListPageFiltersProps = FlexProps

export const ListPageFilters = (props: ListPageFiltersProps) => (
  <Flex
    px={12}
    py={5}
    borderBottom='thin'
    borderBottomColor='grey.50'
    justifyContent='space-between'
    alignItems='center'
    {...props}
  />
)

type ListPageContentProps = FlexProps

export const ListPageContent = ({
  children,
  ...other
}: ListPageContentProps) => {
  return (
    <Flex
      flexDirection='column'
      px={12}
      pb={8}
      minHeight='100%'
      flexGrow={1}
      {...other}
    >
      {children}
    </Flex>
  )
}

interface ListPageProps extends FlexProps {
  children: ReactNode
}

export const ListPage = ({ children, ...other }: ListPageProps) => {
  return (
    <Flex
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
      minHeight='100%'
      flexGrow={1}
      // px={8}
      {...other}
    >
      {children}
    </Flex>
  )
}
