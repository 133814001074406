import { useCallback } from 'react'
import { TableCell, TableRow, Button, Text } from '@woorcs/design-system'
import { NavLink } from 'react-router-dom'

import { OrganizationListOrganizationFragment } from '../__generated__/OrganizationList'

interface OrganizationRowProps {
  organization: OrganizationListOrganizationFragment
  onManageClicked: (id: string) => void
}

export const OrganizationRow = ({
  organization,
  onManageClicked
}: OrganizationRowProps) => {
  const changeOrganization = useCallback(() => {
    onManageClicked(organization.id)
  }, [onManageClicked, organization.id])

  return (
    <TableRow>
      <TableCell>
        <NavLink to={`/organization/${organization.id}`}>
          <Text
            color='grey.900'
            style={{
              textDecoration: 'underline'
            }}
            fontSize='body'
          >
            {organization.name}
          </Text>
        </NavLink>
      </TableCell>

      <TableCell textAlign='right'>
        <Button variant='outlined' size='small' onClick={changeOrganization}>
          Manage
        </Button>
      </TableCell>
    </TableRow>
  )
}
